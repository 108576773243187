.searchModalNav {
  background-color: var(--primary);
  position: fixed;
  top: 0px;
  width: 100%;
  height: 60px;
  align-items: center;
  z-index: 1200;
  display: flex;
}

.searchModalNav .searchBar {
  grid-column: 1/7;
  padding: 10px 0px;
  width: 100%;
}

.searchModalNav input {
  height: 30px;
  width: 100%;
  padding: 0px 10px;
}

.searchModalNav form {
  padding: 0px 15px;
}

.searchModalNav .navicon {
  grid-column: 7/7;
  padding-left: 10px;
}

.searchModalNav button {
  grid-column: 8/8;
  padding: 13px 10px 10px 10px;
  background: none;
  border: none;
  color: white;
  font-weight: bold;
  font-family: var(--secondaryFont);
}

.searchModalSuggestions {
  background: white;
  position: fixed;
  top: 56px;
  bottom: 0px;
  width: 100%;
}

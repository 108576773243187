.pickup {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5px;
  margin-bottom: 20px;
}

.pickup .selectedTime {
  border: gray 1px solid;
  padding: 10px;
}

.pickupNotes {
  margin: 20px 10px;
  height: 30px;
  width: 90%;
  padding-left: 10px;
}

.customPickup {
  padding: 10px;
}

.pickup form div {
  padding-left: 20px;
}

.newLocationsNote {
  margin-top: 20px;
  font-size: 15px;
}

.confirmation {
  text-align: center;
}

.confirmation-checkmark {
  color: #359356;
  height: 70px;
  padding: 30px;
}

.confirmation-text {
  padding: 10px;
}

.confirmation-image {
  padding: 30px;
  height: 200px;
}

.confirmation-link {
  color: black;
  font-weight: bold;
  cursor: pointer;
}

.orderPreview {
  display: flex;
  justify-content: space-between;
  border: 1px grey solid;
  max-width: 500px;
  margin: 20px auto;
  padding: 10px;
}

.orderPreview div {
  padding: 5px;
}

.orderPreview p,
.orderPreview button {
  margin: 5px;
}

.orderPreview .subflex {
  display: flex;
  justify-content: space-between;
}

.orderPreview button {
  font-size: 12px;
  padding: 10px;
  margin: auto 0px;
}

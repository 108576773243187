.checkout-notes {
  grid-column: 1/9;
  grid-row: 1;
  padding: 10px 0px;
  width: 80%;
  border: 2px gray solid;
  border-radius: 20px;
  padding: 25px;
  margin-top: 20px;
}

.checkout-li {
  padding-top: 10px;
}

.subtotal {
  grid-column: 1/9;
  grid-row: 2;
  width: 80%;
  border: 2px gray solid;
  border-radius: 20px;
  padding: 25px;
  margin-top: 20px;
}

.subtotal p {
  padding: 5px;
}

.checkoutForm {
  grid-column: 1/9;
  grid-row: 3;
  padding: 10px 0px;
  width: 80%;
  border: 2px gray solid;
  border-radius: 20px;
  padding: 25px;
  margin: 10px;
}

.checkoutBox {
  display: flex;
  justify-content: space-between;
}

/* small tablet styles */
@media screen and (min-width: 620px) {
  .checkout-notes {
    grid-column: 1/9;
    grid-row: 1;
    border: 2px gray solid;
    border-radius: 20px;
    width: 900px;
    border: none;
  }

  .checkout-page {
    align-items: start;
    max-width: 800px;
    margin: 40px auto;
  }

  .subtotal {
    grid-column: 6/9;
    grid-row: 2;
    border: 2px gray solid;
    border-radius: 20px;
  }

  .checkoutForm {
    grid-column: 1/6;
    grid-row: 2;
    border: 2px gray solid;
    border-radius: 20px;
  }

  .checkoutBox {
    display: flex;
    justify-content: space-between;
  }
}

.fulfillment {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  max-width: 400px;
  border: 1px lightgray solid;
}

.fulfillment p {
  margin: 10px;
}

.fulfillment-buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
}

.fulfillment-buttons .selected {
  background-color: var(--primary);
  text-align: center;
  color: white;
  font-weight: bold;
  padding: 10px 0px;
}

.fulfillment-buttons .not-selected {
  background-color: white;
  border: 1px solid var(--primary);
  text-align: center;
  color: var(--primary);
  font-weight: bold;
  padding: 10px 0px;
}

.fulfillment .question {
  text-align: center;
}

.product-dates {
  max-width: 500px;
  margin: auto;
  padding-top: 50px;
}

.product-dates-flex {
  display: grid;
  grid-template-columns: 1fr 5fr;
  padding-bottom: 30px;
  overflow: hidden;
}

.product-dates-subflex {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-right: 20px;
}

.product-dates-icon {
  color: var(--primary);
  margin-left: auto;
  margin-right: auto;
}

.product-dates-header {
  font-weight: bold;
  color: var(--primary);
}

.product-dates-button {
  font-size: 12px;
  max-width: fit-content;
  padding: 5px 50px;
  margin: 20px auto;
  background-color: #ffffff;
  color: var(--primary);
  border: solid;
  font-weight: bold;
}

.about {
  max-width: 500px;
  margin: 0px auto;
  text-align: center;
}

.about-p {
  padding: 15px;
}

.about-button {
  margin: 10px;
}

@media screen and (min-width: 400px) {
  .about-p {
    text-align: left;
  }
}

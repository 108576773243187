/*Grid with Images*/
.categories-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

@media screen and (min-width: 500px) {
  .categories-grid {
    grid-template-columns: repeat(3, minmax(10rem, 18rem));
    justify-content: center;
  }
}

.category-item {
  text-align: center;
  padding: 20px;
  border: 0.5px lightgrey dotted;
  height: 100px;
  border-radius: 10px;
}

.category-item:hover {
  background-color: #f8f7f5;
}

.category-title {
  color: var(--primary);
  font-family: var(--primaryFont);
}

.category-image {
  height: 75px;
  max-width: 100%;
}

.category-paragraph {
  text-align: center;
  padding: 20px;
  border: 0.5px lightgrey dotted;
  border-radius: 10px;
  font-size: 14px;
  width: fit-content;
  margin: 20px auto;
  width: minmax(10rem, 18rem);
}

.category-h3 {
  margin: auto;
}

.category-paragraph:hover {
  cursor: pointer;
}

.sold-items {
  padding: 10px;
  display: flex;
}

.faTruckFast {
  padding-left: 10px;
}

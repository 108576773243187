.checkoutButton {
  background-color: white;
  color: var(--primary);
}

.totalText {
  color: white;
  font-family: var(--secondaryFont);
  padding: 15px;
}

.whiteSpace {
  height: 55px;
}

.disabledButton {
  display: none;
}

.visibleDisabledButton {
  background-color: white;
  color: var(--primary);
}

.checkOut {
  background-color: var(--primary);
  position: fixed;
  bottom: 0px;
  width: 100%;
  min-height: 55px;
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.window-notification {
  color: white;
  text-align: center;
  margin: 10px;
}

/* small tablet styles */
@media screen and (min-width: 620px) {
  .window-notification {
    display: grid;
  }
}

.navbar {
  background-color: var(--primary);
  position: sticky;
  top: 0px;
  z-index: 1100;
}

.navbar-full-logo {
  display: none;
}

.navbar-short-logo {
  grid-column: 4/6;
  grid-row: 1;
  min-width: 150px;
  max-width: 200px;
  margin-left: auto;
  margin-right: auto;
  padding: 10px 0px;
}

/* small tablet styles */
@media screen and (min-width: 620px) {
  .navbar-full-logo {
    grid-column: 4/6;
    grid-row: 1;
    display: block;
    max-width: 200px;
    margin-left: auto;
    margin-right: auto;
    padding: 10px 0px;
  }

  .navbar-short-logo {
    display: none;
  }
}

.navbar img {
  max-width: 100%;
}

.navbar input,
.navbar button {
  display: none;
}

.navbar .menuIcon {
  grid-column: 1/1;
  grid-row: 1;
  padding: 5px;
}

.navbar .searchIcon {
  grid-column: 7/7;
  grid-row: 1;
  padding: 5px;
}

.navbar .cartIcon {
  grid-column: 8/8;
  grid-row: 1;
  padding: 5px;
  position: relative;
}

.navicon {
  color: white;
  max-width: 100%;
  height: 24px;
  width: 24px;
  padding: 10px;
  cursor: pointer;
}

.cartAmount {
  color: white;
  font-size: 0.6em;
  position: absolute;
  bottom: 4px;
  left: 6px;
  font-family: var(--secondaryFont);
}

.itemCount {
  color: var(--primary);
  font-size: 0.65em;
  font-weight: bold;
  position: absolute;
  top: 9px;
  left: 22px;
  font-family: var(--secondaryFont);
  background-color: var(--secondary);
  border-radius: 100%;
}

.aboveTen {
  width: 15px;
}

.belowTen {
  padding: 0px 3px;
}

.navbar-order-window {
  position: relative;
  width: 100%;
  background-color: var(--secondary);
  text-align: center;
}

.navbar-order-window-2 {
  position: relative;
  width: 100%;
  background-color: #ffe88d;
  text-align: center;
}

.navbar-order-text {
  padding: 0px 25px 0px 0px;
  color: black;
  font-size: 13px;
}

.navbar-order-icon {
  color: rgba(255, 255, 255, 0.8);
  grid-column: 8/9;
  position: absolute;
  top: 0px;
  right: 0px;
}

.navbar-notification-link {
  text-align: center;
  grid-column: 1/9;
}

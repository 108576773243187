.modal-nav {
  width: 256px;
  padding: 16px 0px;
  position: fixed;
  right: 100%;
  top: 0px;
  bottom: 0px;
  overflow: hidden auto;
  transform: translate3d(100%, 0px, 0px);
  transition: transform 500ms ease 0s;
  background-color: white;
  z-index: 1300;
}

.modal-backdrop {
  position: fixed;
  grid-column: 1/9;
  padding: 10px 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1299;
  top: 0px;
}

.navigation-items {
  display: flex;
  flex-direction: column;
  justify-content: left;
  row-gap: 10px;
  padding: 15px;
}

.navigation-item {
  display: flex;
  justify-content: space-between;
}

.navigation-item:hover {
  cursor: pointer;
}

.navigation-flex {
  display: flex;
  gap: 5px;
}

.navigation-flex:hover {
  cursor: pointer;
}

.nav-button {
  position: absolute;
  right: 0px;
  margin: 10px;
}

.navigation-header {
  padding: 0px 10px 0px 10px;
}

.subcategory {
  background: rgb(243, 243, 243);
  padding-left: 10px;
}

.subcategory:hover {
  cursor: pointer;
}

.subcategory .hide {
  display: none;
}

.hide {
  display: none;
  position: absolute;
  bottom: 35px;
}

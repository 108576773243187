.tips-image {
  width: 100%;
}

.tips-parent-header {
  grid-column: 1/9;
  grid-row: 1;
  max-width: 700px;
}

.tips-parent-2 {
  grid-column: 1/9;
  grid-row: 2;
  max-width: 500px;
}

.tips-parent-3 {
  grid-column: 1/9;
  grid-row: 3;
  max-width: 700px;
}

.tips-button {
  margin: 20px;
  grid-column: 1/9;
  grid-row: 4;
}

.tips-child {
  text-align: center;
  background: white;
  padding: 10px 20px;
}

.tips-header {
  font-weight: bold;
  padding: 10px;
}

.tips-icon {
  color: var(--primary);
  height: 40px;
}

.tips-link {
  padding-top: 15px;
  color: var(--primary);
  font-weight: bold;
}

@media screen and (min-width: 620px) {
  .tips {
    margin: 0px auto;
  }

  .tips-parent-header {
    grid-column: 1/9;
    grid-row: 1;
  }

  .tips-parent-2 {
    grid-column: 1/5;
    grid-row: 2;
    justify-self: end;
  }

  .tips-parent-3 {
    grid-column: 5/9;
    grid-row: 2;
    justify-self: start;
  }
}

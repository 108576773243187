.learn {
  text-align: center;
}

.learn-button {
  margin: 30px;
}

.learn-grid {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  margin: 0px auto;
  max-width: 300;
}

.learn-child {
  text-align: center;
  background: white;
  margin: 0px auto;
  border-radius: 10px;
  padding: 20px 20px;
  box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.05);
  width: 80%;
  max-width: 600px;
}

.learn-header {
  font-weight: bold;
  padding: 10px;
}

.learn-justify {
  text-align: justify;
}

.learn-icon {
  color: var(--primary);
  height: 40px;
}

.invoice-grid {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  max-width: 400px;
  margin: 20px auto;
}

.invoice-grid-item {
  border: 1px gray solid;
  background-color: white;
  border-radius: 15px;
}

.invoice-grid-item .totalRadius {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.invoice-grid-item div {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px lightgray solid;
}

.invoice-grid-item div,
.invoice-grid-item p {
  padding: 5px;
}

.invoice-grid-item .cartTotal {
  text-align: center;
  font-weight: bold;
  color: rgb(32, 32, 32);
}

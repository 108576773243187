.order-info-grid {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  margin: 0px auto 100px auto;
  max-width: 300;
}

.order-info-child {
  text-align: center;
  background: white;
  margin: 0px auto;
  border-radius: 10px;
  padding: 20px 20px;
  box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.05);
  max-width: 700px;
}

.order-info-header {
  font-weight: bold;
  padding: 10px;
}

.order-info-icon {
  color: var(--primary);
  height: 40px;
}

/*
@media screen and (min-width: 800px) {
    .order-info-grid {
        display: grid;
        grid-template-columns: repeat(3, 18rem);
        grid-auto-rows: minmax(200px, auto);
        margin: auto;
    }
}
*/

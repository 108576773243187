.center {
  margin-left: auto;
  margin-right: auto;
}

.locationNotes {
  text-align: center;
  width: 100%;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  max-width: 500px;
}

td,
th {
  border: 1px solid #a7a7a7;
  text-align: left;
  padding: 8px;
}

.openSlot {
  background-color: #b7e1cd;
}

.fullSlot {
  background-color: #f8f8f8;
  color: #afafaf;
  text-decoration: line-through;
}

.contact-paragraph {
  padding-bottom: 20px;
}

.contact-link {
  color: black;
}

.contact-link:hover {
  cursor: pointer;
}

.contact-icon {
  padding-bottom: 5px;
}

.contact-whatsapp {
  height: 20px;
}
